.content-display-body {
  min-height: 100vh;
  background-color: #f0f2f5;
}
.display-content-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.titles-container-display-page {
  display: flex;
  justify-content: center;
  width: 100%;
}

.title-display-container {
  text-align: left;
  width: 80%;
}

.display-content {
  width: 80%;
  /* max-height: 600px;
  overflow-y: scroll; */
  margin-top: 20px;
  margin-bottom: 20px;
  /* box-shadow: 0px 1px 2px gainsboro; */
  padding: 20px;
  background-color: #f0f2f5;
}

.display-content .sun-editor-editable {
  border: none;
  background-color: #f0f2f5;
}

.content-display-buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.content-display-buttons {
  width: 80%;
  text-align: right;
  margin-bottom: 20px;
}
.next-prev-button {
  margin: 0px 10px;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
  background: gainsboro;
  color: black;
  text-align: center;
  width: 100px;
  border: none;
  box-shadow: 0px 1px 2px gainsboro;
  outline: none;
}

.accept-reject-button {
  font-size: 12px;
  padding: 5px;
  border-radius: 10px;
  background-color: #dbdbdb;
  color: black;
  border: 1px solid gray;
}
