.chat-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 50px;
  width: 350px;
  z-index: 10;
}

.my-cfg-family-text {
  flex: 3;
  text-align: 'left';
  font: 'normal normal 300 25px/33px Segoe UI';
  letter-spacing: '0.32px';
  color: '#919191';
  min-width: 200;
  margin-left: 7px;
}

.online {
  border: 2px solid #10e010;
}

.offline {
  border: 2px solid red;
}

.user_name {
  text-align: left;
  /* font: normal normal bold 12px/18px Segoe UI; */
  letter-spacing: 0.25px;
  color: #383838;
  float: left;
  padding: left 5px;
  margin-left: 12px;
  margin-top: 10px;
  font-size: small;
}

.circle-modal {
  max-width: 50px;
  height: 50px;
  background: rgb(246, 156, 85);
  border-radius: 50%;
  float: left;
}

.online-icon {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background-color: #05af05;
  position: relative;
  top: 25px;
  right: 8px;
}

.offline-icon {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background-color: red;
  position: relative;
  top: 25px;
  right: 8px;
}

.conversationHeader {
  font-weight: 600;
  font-size: 16px;
  color: #929292;
}
.conversation-child-list {
  list-style-type: none;
  font-size: 12px;
  margin-left: 10px;
  color: #929292;
}

.conversation-child-element {
  margin-top: 10px;
  padding-bottom: 10px;
}

.conversation-container {
  /* position: relative;
  min-height: 1300px; */
}
.conversation-lists {
  position: relative;

  top: -10px;
  left: 32px;
  color: grey !important;
}

.collapse-style {
  height: 200px !important;
  overflow-y: scroll !important;
}

.collapse-style::-webkit-scrollbar {
  width: 5px;
  background-color: gainsboro;
  border-radius: 5px;
}

.collapse-style::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.subtitle {
  list-style-type: none;
  padding-left: 10px;
}

.journal-text {
  position: absolute;
  top: 5%;
  left: 55%;
  color: white;
  width: 43%;
  height: 80%;
  border: 2px solid #e7ddcb;
  opacity: 0.8;
  background-color: #f7f5f3;
  border-radius: 10px;
}

.journey-heading {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: red;
  border-bottom: 2px solid rgb(211 199 172 / 100%);
  margin-bottom: 0px;
}

.journal-list-container {
  overflow-y: scroll;
  height: 220px;
}

.journal-list-container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.journal-subject {
  margin: 0;
  font-size: 18px;
  color: #1c1c1c;
  font-weight: 500;
}

.journal-heading {
  margin-bottom: 0;
}

.journal-list-element {
  padding: 5px;
  border-bottom: 2px solid rgb(211 199 172 / 100%);
}

@media screen and (max-width: 600px) {
  .journal-text {
    left: 2.5%;
    width: 95%;
    opacity: 0.8;
    background-color: #f9f3ed;
    height: 85%;
    top: 4%;
  }
}

.MuiListItemIcon-root {
  min-width: 30px !important;
}
.upcoming-event-text {
  padding-left: 20px;
  color: #949494;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.contacts-rectangle {
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  /* border: 1px solid #ffffff; */
  border-radius: 20px 0px 0px 20px;
  opacity: 1;
  overflow-y: auto;
  overflow-x: auto;
  min-height: 400px;
  padding: 10px;
  /* box-shadow: 0px 6px 6px #00000029; */
}

.contacts-rectangle {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.contacts-rectangle::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.circle {
  width: 40px;
  height: 40px;
  background: rgb(246, 156, 85);
  border-radius: 50%;
  float: left;
}

.chat_user {
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.chat-rectangle {
  height: 460px;
  /* border: 1px solid black; */
  border: none;
  z-index: 9;
  /* UI Properties */
  opacity: 1;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 15px 15px 5px 15px;
  box-shadow: -4px -2px 13px #cccccc;
}

.user_name_chat_box {
  text-align: left;
  font: normal normal bold 22px Segoe UI;
  font-size: 18px;
  color: #919191;
  opacity: 1;
  float: left;
  margin-left: 5px;
  margin-top: 5px;
}
.msg-box {
  height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
}

.chat_input_div {
  border-radius: 0px 0px 0px 15px;
  height: 55px;
}

.chat_input {
  border-radius: 10px;
  height: 35px;
  margin: 5px;
  margin-top: 10px;
  margin-left: 15px;
  width: 80%;
  padding: 3px;
  border: none;
  border-radius: 20px;
  background-color: lightgray;
  padding-left: 10px;
}

.chat_user_chat_box {
  margin-left: 5px;
  margin-top: 5px;
}

/* .msg-text-right{
  text-align:left;
  float:left;
  background-color:#F3F3F3;
} */
.chat-parent-right {
  background-color: #608ef1;
  margin-right: 10px;
  margin-left: 120px;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 5px;
  margin-bottom: 10px;
  /* border: 1px solid black; */
  border-radius: 25px;
  color: white;
  font-size: 16px;
}

.chat-parent-left {
  margin-right: 120px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 5px;
  margin-bottom: 10px;
  /* border: 1px solid black; */
  border-radius: 25px;
  color: white;
  font-size: 16px;
  background-color: #c9c9c9;
  color: #292828;
}

.testdiv1 {
  word-wrap: break-all;
  text-align: left;
  padding-bottom: 7px;
}

.testdiv2 {
  text-align: center;
  margin-bottom: 8px;
  font-size: 12px;
  color: #535353;
}

.cancel-icon {
  color: red;
  float: right;
  align: right;
  cursor: pointer;
  position: relative;
  left: 125px;
}

@media screen and (max-width: 450px) {
  .cancel-icon {
    left: 100px;
  }
}

@media screen and (max-width: 400px) {
  .cancel-icon {
    left: 80px;
  }
}

@media screen and (max-width: 340px) {
  .cancel-icon {
    left: 60px;
  }
}

@media screen and (min-width: 1850px) {
  .chat-container {
    width: 400px;
  }
}

@media screen and (min-width: 1600px) {
  .chat-container {
    width: 300px;
  }
}

@media screen and (max-width: 1500px) {
  .chat-container {
    width: 250px;
  }
}

@media screen and (max-width: 1300px) {
  .chat-container {
    width: 180px;
  }
}

@media screen and (max-width: 950px) {
  .chat-container {
    width: 50%;
  }
}

@media screen and (max-width: 750px) {
  .chat-container {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .chat-container {
    width: 80%;
  }
}

@media screen and (max-width: 780px) {
  .circle-modal {
    max-width: 40px;
    height: 40px;
    background: rgb(246, 156, 85);
    border-radius: 50%;
    float: left;
  }
}
