.user-achievement-page {
  min-height: 100vh;
}
.user-achievement-container {
  display: flex;
  min-height: 100vh;
  justify-content: space-between;
  background-color: #f9f9f9;
}

.user-achievement-left {
  width: 22%;
  overflow-y: scroll;
  padding: 10px;
  margin-top: 20px;
}
.user-achievement-center {
  width: 52%;
  overflow-y: scroll;
  margin-top: 20px;
  height: 100vh;
}
.user-achievement-right {
  width: 22%;
  overflow-y: scroll;
  margin-top: 20px;
  padding: 10px;
}
.achievement-card-style {
  margin: 10px;
  box-shadow: 0px 1px 2px gainsboro;
}
.list-tile-with-icon {
  display: flex;
  align-items: center;
}

.user-achievement-mobile-view {
  display: none;
}

@media only screen and (max-width: 970px) {
  .user-achievement-mobile-view {
    display: block;
  }
}
